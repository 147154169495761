<template>
  <div class="px-2 mt-1">
    <header-slot />
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: 'specialistdigital-workplan-pending' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        Pending
      </b-nav-item>
      <b-nav-item
        :to="{ name: 'specialistdigital-workplan-completed' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        Completed
      </b-nav-item>
      <b-nav-item
        :to="{ name: 'specialistdigital-workplan-annul' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        ANNUL
      </b-nav-item>
    </b-nav>
    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.fullPath" />
    </b-card>
  </div>
</template>
<script>
export default {};
</script>

<style></style>
